import { TrayPreview, FadeableLocation } from './tray-preview';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, EnumerationTypeService, Various, ActionDialogBoxInputParameters } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom, Disposable } from 'aurelia-framework';
import { Zeus } from "../generated";
import { EntityTypeNames, EnumerationTypes, InventoryBlockId } from '../constants';
import { DataFormat } from 'select2';
import * as toastr from 'toastr';
import { Utils } from '../utils/utils';
import { UnifiedStorageEntity } from 'storages/storage-tree-panel';
import { LocationUtils } from '../utils/utils-locations';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import * as MSConstants from 'app-modules/mobile-screens/constants';
import { StaticRackLocationApiService } from 'app-modules/mobile-screens/api-services/location-api-service';
import { MobileScreens } from 'app-modules/mobile-screens/generated';
import { ValidationRules } from 'aurelia-validation';

@autoinject
export class LocationDetail extends EntityDetailViewModelBase<Zeus.Web.Model.Location | MobileScreens.Model.StaticRackLocation> {
  private EnumerationTypes = EnumerationTypes;

  private locationVolumeService: ServiceBase<Zeus.Web.Model.LocationVolume>;
  private storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  private trayContainerService: ServiceBase<Zeus.Web.Model.TrayContainer>;
  private applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  private articleRotationLevelTypeService: EnumerationTypeService;
  private storageLocationTypeService: EnumerationTypeService;

  private positionXInput: HTMLElement;

  public ressourceName: string = EntityTypeNames.Location;
  private applicationParameter: Zeus.Web.Model.ApplicationParameters;

  private trayLocations: FadeableLocation[] = [];

  private storageVolume: DataFormat;
  private positionX: number;
  private positionY: number;
  private positionXAlias: number;
  private positionYAlias: number;
  private trayPreview: TrayPreview;

  private readonly functionNameGenerateLocation = "generateLocation";

  private isRestrictedTraysActive: boolean = false;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private appModuleService: AppModuleService, private locationApiService: StaticRackLocationApiService) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.Location | MobileScreens.Model.StaticRackLocation>(EntityTypeNames.Location));

    this.locationVolumeService = new ServiceBase<Zeus.Web.Model.LocationVolume>(EntityTypeNames.LocationVolume);

    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];

    this.trayContainerService = new ServiceBase<Zeus.Web.Model.TrayContainer>(EntityTypeNames.TrayContainer);
    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(EntityTypeNames.ApplicationParameters);
    this.articleRotationLevelTypeService = new EnumerationTypeService(EnumerationTypes.ArticleRotationLevel);
    this.storageLocationTypeService = new EnumerationTypeService(EnumerationTypes.StorageLocationType);
  }

  public async activate(params: { param1: number, storage?: number, tray?: number, callback?: (entity: any) => void | Promise<void>; }) {
    this.applicationParameter = await this.applicationParameterService.firstEntity();
    this.isRestrictedTraysActive = this.appModuleService.isActive(AppModuleEnum.RestrictedTrays);

    if (params.callback) {
      params.callback(params);
    }

    let id: number = typeof params.param1 === 'string' ? parseInt(params.param1) : params.param1;
    if (isNaN(id)) {
      id = null;
    }
    await this.load(id);

    if (params.storage && id == Various.NewId) {
      this.entity.storageId = params.storage;
      if (params.tray) {
        await this.trayContainerService.getEntityById(params.tray);
        this.entity.trayContainerId = params.tray;
        this.trayLocations = await this.service.getEntities(new Predicate("trayContainerId", FilterQueryOp.Equals, this.entity.trayContainerId), ['defaultStorageVolume.volumeType']);
      }
    }
    if (id > 0) {
      this.trayLocations.forEach((loc: FadeableLocation) => loc.fade = loc.id != id);
    }

    this.dialogRightActions.push(
      { label: "location.generateLocation", title: "location.generateLocation", icon: "digi-search", closeDialog: false, fn: this.functionNameGenerateLocation, theme: "secondary", disabled: true } as ActionDialogBoxInputParameters,
      { label: "general.saveAndNew", title: "general.saveAndNew", icon: "digi-save", closeDialog: true, fn: "saveAndNew", theme: "primary" } as ActionDialogBoxInputParameters
    );

    super.activate(params)
  }

  async addNew(): Promise<void> {
    this.router.navigate("/locations/all");
    await LocationUtils.addLocationModal();
  }

  get ribbonHeaderText(): string {
    return this.isCreationMode ? this.i18n.tr('location.createLocation') : this.i18n.tr('location.location');
  }

  private async load(id: number | null) {
    if (id == Various.NewId || id == null) {
      this.editingMode = EditingModeEnum.Create;
      if (id == Various.NewId) {
        this.entity = await this.service.createEntity({ defaultCapacityInVolume: 1, locationBlockId: InventoryBlockId.Unlocked });
      }
      else {
        this.entity = null;
      }
    } else {
      this.editingMode = EditingModeEnum.Update;
      await this.loadDetails(id);
    }
    this.trayContainerService.gridDataSource.customSelect2Predicates = () => {
      let trayPredicate = new Predicate("storageId", FilterQueryOp.Equals, this.entity.storageId).and(new Predicate("isUnavailable", FilterQueryOp.Equals, false));
      if (this.isRestrictedTraysActive) {
        trayPredicate = trayPredicate.and(new Predicate("isRestricted", FilterQueryOp.Equals, false));
      }
      return trayPredicate;
    }
  }

  protected storageExpands = ['locationFormat.locationLevels'];

  private async loadDetails(id: number) {

    this.entity = await this.service.getEntityById(
      id,
      'storage.locationFormat.locationLevels',
      'defaultStorageVolume.volumeType',
      'dedicatedToRotationLevel',
      'storageLocationType',
      'trayContainer',
      'locationBlock'
    );

    this.trayLocations = await this.service.getEntities(new Predicate("trayContainerId", FilterQueryOp.Equals, this.entity.trayContainerId), ['defaultStorageVolume.volumeType']);

    if (this.isStatic) {
      super.initialize(new ServiceBase<MobileScreens.Model.StaticRackLocation>(MSConstants.EntityTypeNames.StaticRackLocation));
    } else {
      super.initialize(new ServiceBase<Zeus.Web.Model.Location>(EntityTypeNames.Location));
    }

    this.storageVolume = {
      id: this.entity.defaultStorageVolumeId,
      text: this.entity.defaultStorageVolume.volumeType.denomination._translation
    } as DataFormat;

    [this.positionX, this.positionY] = LocationUtils.getIntCoordFromIdentifier(this.entity.locationIdentifier);
    [this.positionXAlias, this.positionYAlias] = LocationUtils.getIntCoordFromIdentifier(this.entity.locationIdentifierAlias);

    this.controller.addObject(this.entity);
  }

  @computedFrom('editingMode', 'entity.locationIdentifier')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("location.location");
    } else if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.locationIdentifier;
    }
  }

  @computedFrom('entity.storage.nbTrays')
  private get trayRange() {
    if (this.entity?.storage?.nbTrays > 0) {
      return `1-${this.entity.storage.nbTrays}`
    } else {
      return this.i18n.tr('storage.noTraysInStorage');
    }
  }

  @computedFrom('entity.storage.storageTypeId')
  protected get isStatic(): boolean {
    return this.entity?.storage?.storageTypeId == MSConstants.StorageTypes.Static ?? false;
  }

  @computedFrom('entity.storage.locationFormat.locationLevels')
  protected get rowRange(): string {
    return this.entity?.storage?.locationFormat?.locationLevels.find(ll => ll.level == MSConstants.EnumLocationLevelLevels.Row)?.valueRange;
  }

  @computedFrom('entity.storage.locationFormat.locationLevels')
  protected get bayRange(): string {
    return this.entity?.storage?.locationFormat?.locationLevels.find(ll => ll.level == MSConstants.EnumLocationLevelLevels.Bay)?.valueRange;
  }

  @computedFrom('entity.storage.locationFormat.locationLevels')
  protected get levelRange(): string {
    return this.entity?.storage?.locationFormat?.locationLevels.find(ll => ll.level == MSConstants.EnumLocationLevelLevels.Level)?.valueRange;
  }

  @computedFrom('entity.storage.locationFormat.locationLevels')
  protected get positionRange(): string {
    return this.entity?.storage?.locationFormat?.locationLevels.find(ll => ll.level == MSConstants.EnumLocationLevelLevels.Position)?.valueRange;
  }

  @computedFrom('entity.storage.locationFormat.locationLevels')
  protected get compartmentRange(): string {
    return this.entity?.storage?.locationFormat?.locationLevels.find(ll => ll.level == MSConstants.EnumLocationLevelLevels.Compartment)?.valueRange;
  }

  @computedFrom('entity.storage.locationFormat.locationLevels')
  protected get excludeCompartment(): boolean {
    return this.entity?.storage?.locationFormat?.locationLevels.find(ll => ll.level == MSConstants.EnumLocationLevelLevels.Compartment)?.excludeFromAlias ?? false;
  }


  private bindingEngineDisposables: Disposable[] = [];

  public async attached() {
    this.bindingEngineDisposables.push(
      this.bindingEngine.propertyObserver(this.entity, "defaultStorageVolumeId").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          if (newValue != null) {
            this.entity.storageUnitWidth = this.entity.defaultStorageVolume.width;
            this.entity.storageUnitLength = this.entity.defaultStorageVolume.depth;
          }
        }
      }),
      this.bindingEngine.propertyObserver(this.entity, "storageId").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.entity.trayContainerId = null;
          if (!this.isStatic) {
            this.entity.locationIdentifier = this.getLocationIdentifier(this.positionX, this.positionY);
          }
          if (this.entity.storage?.storageLocationTypeId != null) {
            this.entity.storageLocationType = await this.storageLocationTypeService.getEntityById(this.entity.storage.storageLocationTypeId);
          } else {
            this.entity.storageLocationType = null;
          }
        }
      }),

      // For 'dynamic' location
      this.bindingEngine.propertyObserver(this.entity, "trayContainerId").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.trayLocations = await this.service.getEntities(new Predicate("trayContainerId", FilterQueryOp.Equals, this.entity.trayContainerId), ['defaultStorageVolume.volumeType']);
          this.trayLocations.filter(tray => (tray as any).fade).forEach(tray => delete (tray as any).fade);
          this.entity.locationIdentifier = this.getLocationIdentifier(this.positionX, this.positionY);
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionX").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifier = this.getLocationIdentifier(this.positionX, this.positionY);
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionY").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifier = this.getLocationIdentifier(this.positionX, this.positionY);
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionXAlias").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifierAlias = this.getLocationIdentifier(this.positionXAlias, this.positionYAlias);
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionYAlias").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifierAlias = this.getLocationIdentifier(this.positionXAlias, this.positionYAlias);
        }
      }),
    );

    // For static locations, validate on save
    ValidationRules
      .ensure('row').required().when(() => this.isStatic)
      .satisfies(async (value: string) => {
        return await this.valueIsInRange(value, MSConstants.EnumLocationLevelLevels.Row);
      }).when(() => this.isStatic).withMessage(this.i18n.tr('staticracklocation.rowOutOfRange'))

      .ensure('bay').required().when(() => this.isStatic)
      .satisfies(async (value: string) => {
        return await this.valueIsInRange(value, MSConstants.EnumLocationLevelLevels.Bay);
      }).when(() => this.isStatic).withMessage(this.i18n.tr('staticracklocation.bayOutOfRange'))

      .ensure('level').required().when(() => this.isStatic)
      .satisfies(async (value: string) => {
        return await this.valueIsInRange(value, MSConstants.EnumLocationLevelLevels.Level);
      }).when(() => this.isStatic).withMessage(this.i18n.tr('staticracklocation.levelOutOfRange'))

      .ensure('position').required().when(() => this.isStatic)
      .satisfies(async (value: string) => {
        return await this.valueIsInRange(value, MSConstants.EnumLocationLevelLevels.Position);
      }).when(() => this.isStatic).withMessage(this.i18n.tr('staticracklocation.positionOutOfRange'))

      .ensure('compartment').required().when(() => this.isStatic && !this.excludeCompartment)
      .satisfies(async (value: string) => {
        return await this.valueIsInRange(value, MSConstants.EnumLocationLevelLevels.Compartment);
      }).when(() => this.isStatic && !this.excludeCompartment).withMessage(this.i18n.tr('staticracklocation.compartmentOutOfRange'))

      .ensure('sequence').satisfies(async (value: string) => {
        return await this.locationApiService.sequenceIsUnique({ sequence: value, entityId: this.entity.id, storageId: this.entity.storage.id });
      }).when(() => this.isStatic).withMessage(this.i18n.tr('staticracklocation.sequenceMustBeUnique'))

      .on(this.entity);
  }

  detached(): void {
    this.bindingEngineDisposables.forEach(d => d.dispose());
    this.entityManager.manager.clear();
    super.detached();
  }

  private async valueIsInRange(value: string, EnumLocationLevelLevels: number): Promise<boolean> {
    let valueRange = this.valueRangeFromLocationLevel(EnumLocationLevelLevels);
    if (value?.length == 0 || valueRange?.length == 0) return true;
    return await this.locationApiService.valueIsInRange({ value: value, valueRange: valueRange });
  }

  private valueRangeFromLocationLevel(EnumLocationLevelLevels: number): string {
    return this.entity.storage.locationFormat.locationLevels.find(ll => ll.level == EnumLocationLevelLevels)?.valueRange;
  }

  private padStartElem(str): string {
    if (!isNaN(str)) {
      str = str.toString();
    }
    return str.padStart(this.applicationParameter.leadingZeros, "0");
  }

  private getLocationIdentifier(posX, posY): string {
    if (this.entity.storage != null && this.entity.trayContainer != null && posX != null && posY != null && !Utils.hasDecimals(posX) && !Utils.hasDecimals(posY)) {
      if (this.applicationParameter.leadingZeros != null) {
        return this.padStartElem(this.entity.storage.dynamicStorageId).concat(".", this.padStartElem(this.entity.trayContainer.trayId), ".", this.padStartElem(posX), ".", this.padStartElem(posY))
      }
      else {
        return this.entity.storage.dynamicStorageId + "." + this.entity.trayContainer.trayId + "." + posX + "." + posY;
      }
    }
    else {
      return null;
    }
  }


  private isNodeSelectable(entity: UnifiedStorageEntity) {
    return this.isCreationMode ? !!entity.storage : entity.storage?.id == this.entity?.storageId;
  }

  protected async onStorageSelected(storage: Zeus.Web.Model.Storage) {
    this.entity.storage = storage;
    let isStaticStorage = storage.storageTypeId == MSConstants.StorageTypes.Static;

    if (isStaticStorage) {
      super.initialize(new ServiceBase<MobileScreens.Model.StaticRackLocation>(MSConstants.EntityTypeNames.StaticRackLocation));
    } else {
      super.initialize(new ServiceBase<Zeus.Web.Model.Location>(EntityTypeNames.Location));
    }

    if (this.isCreationMode) {
      for (let d of this.bindingEngineDisposables) {
        d.dispose();
      }
      this.entity = await this.service.createEntity({ defaultCapacityInVolume: 1, locationBlockId: InventoryBlockId.Unlocked });
      this.attached();
    }

    let btnGenerateLocation = this.dialogRightActions.find(a => a.fn == this.functionNameGenerateLocation);
    if (btnGenerateLocation != null) {
      btnGenerateLocation.disabled = isStaticStorage;
    }
  }

  public async generateLocation() {
    if (await this.beforeSave()) {
      (this.entity as any).fade = true;
      // this.entity.entityAspect.setDeleted();
      this.trayLocations.push(this.entity);
      this.trayPreview?.redraw();
    }
  }

  public async saveAndNew() {
    if (await this.beforeSave()) {
      await this.saveCurrentEntity();
      await LocationUtils.addLocationModal(this.entity.storageId, this.entity.trayContainerId);
    }
  }

  protected async beforeSave(): Promise<boolean | void> {
    if (!this.isStatic) {
      if (isNaN(this.positionX) || Utils.hasDecimals(this.positionX)) {
        toastr.error(this.i18n.tr("location.invalidPositionX"));
        return false;
      }

      if (isNaN(this.positionY) || Utils.hasDecimals(this.positionY)) {
        toastr.error(this.i18n.tr("location.invalidPositionY"));
        return false;
      }

      if (this.positionX < 1 || this.positionX > this.entity.storage.trayWidthCm) {
        toastr.error(this.i18n.tr("location.positionXOutOfTray"));
        return false;
      }

      if (this.positionY < 1 || this.positionY > this.entity.storage.trayDepthCm) {
        toastr.error(this.i18n.tr("location.positionYOutOfTray"));
        return false;
      }

      if ((this.positionXAlias && this.positionYAlias == null) || (this.positionXAlias == null && this.positionYAlias)) {
        toastr.error(this.i18n.tr("location.invalidPositionAlias"));
        return false;
      }

      if (this.positionXAlias && (isNaN(this.positionXAlias) || Utils.hasDecimals(this.positionXAlias))) {
        toastr.error(this.i18n.tr("location.invalidPositionXAlias"));
        return false;
      }

      if (this.positionYAlias && (isNaN(this.positionYAlias) || Utils.hasDecimals(this.positionYAlias))) {
        toastr.error(this.i18n.tr("location.invalidPositionYAlias"));
        return false;
      }

      if (this.entity.trayContainerId == null) {
        toastr.error(this.i18n.tr("location.trayContainerMandatory"));
        return false;
      }

      if (isNaN(this.entity.storageUnitWidth) || this.entity.storageUnitWidth < 1 || this.entity.storageUnitWidth > this.entity.storage.trayWidthCm) {
        toastr.error(this.i18n.tr("location.widthOutOfTray"));
        return false;
      }

      if (isNaN(this.entity.storageUnitLength) || this.entity.storageUnitLength < 1 || this.entity.storageUnitLength > this.entity.storage.trayDepthCm) {
        toastr.error(this.i18n.tr("location.lengthOutOfTray"));
        return false;
      }

      if (this.entity.storageUnitWidth == null) {
        toastr.error(this.i18n.tr("location.widthMandatory"));
        return false;
      }

      if (this.entity.storageUnitLength == null) {
        toastr.error(this.i18n.tr("location.lengthMandatory"));
        return false;
      }

      if (await this.service.getCount(new Predicate("locationIdentifier", FilterQueryOp.Equals, this.entity.locationIdentifier)
        .and(new Predicate("id", FilterQueryOp.NotEquals, this.entity.id))) > 0) {
        toastr.error(this.i18n.tr("location.locationAlreadyExists"));
        return false;
      }
    } else {// Static storage
      if (this.isCreationMode) {
        this.entity.locationIdentifier = "temporary";// It's defined by the ModelBreezeService in the AfterAddEntity.
      }
    }

    if (this.entity.defaultStorageVolumeId == null) {
      toastr.error(this.i18n.tr(this.isStatic ? "staticracklocation.volumeFieldRequired" : "location.volumeMandatory"));
      return false;
    }

    return true;
  }

  protected async afterSave(): Promise<void> {
    if (!this.isCreationMode) {
      this.trayPreview?.redraw();
    }
    return await super.afterSave();
  }

  public async navigateBack(): Promise<void> {
    let result = await this.canDeactivate();
    if (result) {
      this.navigateTo('locations/all');
    }
  }

  public focusPositionXInput() {
    if (this.positionXInput?.firstElementChild != null) {
      (<HTMLElement>this.positionXInput.firstElementChild).focus();
    }
  }
}

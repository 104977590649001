import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, Various, TranslatedProperty } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom, Container } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { Endpoints } from '../app-modules/mobile-screens/endpoints';
import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine } from 'aurelia-binding';
import * as MobileConstants from '../app-modules/mobile-screens/constants'
import { AppModuleService } from 'app-modules/app-module-service';
import * as AMConstants from 'app-modules/constants';


@autoinject
export class LocationFormatDetail extends EntityDetailViewModelBase<Zeus.Web.Model.LocationFormat> {
  public fromDialogBox: boolean = false;
  private httpClient: HttpClient;

  public ressourceName: string = Constants.EntityTypeNames.LocationFormat;
  public locationLevelsService: ServiceBase<Zeus.Web.Model.LocationLevel>;

  public fetchedLocationLevels: Zeus.Web.Model.LocationLevel[];
  private mobileScreensAppModuleActive: boolean = false;


  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.LocationFormat>(Constants.EntityTypeNames.LocationFormat));
    this.locationLevelsService = new ServiceBase<Zeus.Web.Model.LocationLevel>(Constants.EntityTypeNames.LocationLevel);
    this.httpClient = Container.instance.get(HttpClient);
    this.mobileScreensAppModuleActive = this.appModuleService.isActive(AMConstants.AppModuleEnum.MobileScreens);
  }

  @computedFrom('entity', 'editingMode', 'entity.isDynamicStorageFormat')
  public get isDynamicLocationFormat(): boolean {
    return this.entity?.isDynamicStorageFormat;
  }

  public async activate(params) {
    await super.activate(params);
    const id = params.param1;

    if (id == Various.NewId) {
      if (!this.mobileScreensAppModuleActive) {
        this.navigateTo('/location-formats/all');
      }
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      if (params.callback) {
        params.callback(this.entity);
        this.fromDialogBox = true;
      }
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }

    await this.fetchLocationLevels();
    if (this.editingMode == EditingModeEnum.Update && this.entity.locationLevels.length == 0) {
      await this.fetchLocationLevelsForStatic();
    }
    this.entity.locationLevels.forEach(locationLevel => {
      this.addLocationLevelToObserver(locationLevel);
    })
  }

  public addLocationLevelToObserver(locationLevel: Zeus.Web.Model.LocationLevel) {
    this.disposables.push(

      this.bindingEngine.propertyObserver(locationLevel, 'numberOfCharacters').subscribe(async newValue => {
        if (newValue > 0) {
          locationLevel.numberOfCharacters = 3;
        } else if (newValue < 0) {
          locationLevel.numberOfCharacters = 0;
        }
      })
    )
  }

  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("locationformat.addLocationFormat");
    } else {
      return this.entity.name;
    }
  }

  // Parsing all locationLevel and take the max
  public get getMaxLevel() {
    return Math.max.apply(Math, this.fetchedLocationLevels.map(function (o) { return o.level; }));
  }

  public minIsNotNumber(min: any) {
    return isNaN(min?.toString());
  }

  public isLetter(char: string): boolean {
    return /^[a-zA-Z]$/.test(char);
  }


  public isHigherInAlphabet(char1: string, char2: string): boolean {
    const lowerChar1 = char1.toLowerCase();
    const lowerChar2 = char2.toLowerCase();
    return lowerChar1 < lowerChar2;
  }

  // Display the trash icon
  public displayTrash(level: number, levelId: number) {
    return false == this.isDynamicLocationFormat && (level == this.getMaxLevel || levelId < 0);
  }

  // Display the accordeon if location format saved
  get isSaved() {
    return this.entity.id > 0;
  }

  public async saveCurrentEntity(silentSave?: boolean) {
    if (this.fromDialogBox) {
      await super.saveCurrentEntity();
      this.entity.locationLevels?.forEach(location => {
        this.locationLevelsService.saveEntity(location);
      });
    } else {
      await this.save();
    }
    return this.entity;
  }

  public async save() {
    await super.save();
    this.entity = await this.service.getEntityById(this.entity.id);

    this.loadingLocationLevels = true;
    await this.fetchLocationLevels();
  }

  protected async fetchLocationLevelsForStatic() {
    if (this.entity.locationLevels.length == 0) {
      let url = Endpoints.StaticRackLocation.GetLocationLevelForStaticDto + "?locationFormatId=" + this.entity.id;
      let response = await this.httpClient.fetch(url);
      if (response.ok) {
        this.entity.locationLevels.splice(0);
        let locationLevels: Zeus.Web.Model.LocationLevelDTO[] = await response.json();
        locationLevels.forEach(async locationLevel => {
          this.entity.locationLevels.push(... await this.locationLevelsService.createEntity({
            descriptionName: locationLevel.descriptionName,
            level: locationLevel.level,
            separator: locationLevel.separator,
            locationFormatId: locationLevel.locationFormatId,
            numberOfCharacters: 1,
          }));
        });
      }
    }
  }

  private loadingLocationLevels: boolean = true;
  public async fetchLocationLevels() {
    if (this.loadingLocationLevels) {
      this.fetchedLocationLevels = await this.locationLevelsService.getEntities(null, null, { locationFormatId: this.entity.id });
      this.parseLevelRanges();

      this.loadingLocationLevels = false;
    }
  }

  public async addLocationLevel() {
    if (this.isDynamicLocationFormat) {
      return;
    }
    let newLocationLevel = await this.locationLevelsService.createEntity();
    newLocationLevel.locationFormatId = this.entity.id;
  }

  public async removeLocationLevel(locationLevel: Zeus.Web.Model.LocationLevel) {
    if (this.isDynamicLocationFormat) {
      return;
    }
    await this.locationLevelsService.deleteEntities([locationLevel], true);
    this.loadingLocationLevels = true;
    await this.fetchLocationLevels();
  }

  public get levelsTitle(): string {
    let format = this.entity?.locationLevels
      ?.filter(locationLevel => !locationLevel.excludeFromAlias && !this.isDescriptionName(locationLevel.level))
      .map(locationLevel => (locationLevel.valueRange ? locationLevel.valueRange : '0-0') + (locationLevel.separator || ''))
      .join('');
    return this.i18n.tr('locationlevel.locationlevels') + (format ? ': ' + format : '');
  }


  private parseLevelRanges() {
    this.entity.locationLevels?.filter(level => !level.excludeFromAlias).
      forEach(level => {
        let matches = /^([^-]+)(-([^-]+))?$/.exec(level.valueRange);
        if (matches) {
          (level as any).min = matches[1];
          if (matches[3]) {
            // Range
            (level as any).max = matches[3];
          } else {
            // Single value
            (level as any).max = (level as any).min;
          }
        }
      });
  }

  parseIfNumber(value: string): number | null {
    if (value != null) {
      if (!isNaN(Number(value)) && value.trim() !== '') {
        return Number(value);
      }
    } else {
      return null;
    }
  }

  public isDescriptionName(level: number): boolean {
    return !this.isDynamicLocationFormat && level == MobileConstants.EnumLocationLevelLevels.StaticStorageName;
  }

  public isCompartment(level: number): boolean {
    return !this.isDynamicLocationFormat && level == MobileConstants.EnumLocationLevelLevels.Compartment;
  }

  public calculateRange(locationLevel: Zeus.Web.Model.LocationLevel, minMax: 'min' | 'max') {
    if (minMax == 'min') {
      // Range start changed
      if (locationLevel.min && locationLevel.max && !this.minIsNotNumber(locationLevel.min)) {
        locationLevel.max = Math.max(this.parseIfNumber(locationLevel.min), this.parseIfNumber(locationLevel.max) || 0).toString();
      }
    } else {
      // Range end changed
      if (locationLevel.max && !this.minIsNotNumber(locationLevel.min) && !this.minIsNotNumber(locationLevel.max) && locationLevel.min != locationLevel.max) {
        const min = locationLevel.min;
        const max = locationLevel.max;
        locationLevel.min = Math.min(this.parseIfNumber(max), this.parseIfNumber(min) || 0).toString();
        locationLevel.max = Math.max(this.parseIfNumber(max), this.parseIfNumber(min) || 0).toString();
      }
    }

    if (locationLevel.min && locationLevel.max) {
      if (!this.isLetter(locationLevel.min) && !this.isLetter(locationLevel.max)) {
        const numberOfChars = locationLevel.numberOfCharacters < 3 ? locationLevel.numberOfCharacters : 3;
        locationLevel.min = locationLevel.min.padStart(numberOfChars, '0');
        locationLevel.max = locationLevel.max.padStart(numberOfChars, '0');
      }
      if (this.isLetter(locationLevel.min) && this.isLetter(locationLevel.max)) {
        if (this.isHigherInAlphabet(locationLevel.max, locationLevel.min)) {
          const minTemp = locationLevel.min;
          locationLevel.min = locationLevel.max;
          locationLevel.max = minTemp;
        }
        locationLevel.valueRange = locationLevel.min.toString() + "-" + locationLevel.max.toString();
      } else {
        (locationLevel as Zeus.Web.Model.LocationLevel).valueRange = locationLevel.min + "-" + locationLevel.max;
      }
    } else {
      (locationLevel as Zeus.Web.Model.LocationLevel).valueRange = null;
    }
  }

}

import { bindingMode, Disposable, observable } from "aurelia-binding";
import { autoinject, bindable, children, customElement, processContent } from "aurelia-framework";
import * as breeze from 'breeze-client';
import { GlobalLoaderService, ServiceBase, UIInternal } from "digiwall-lib";
import * as Constants from "../../../constants";

@customElement('ui-table-pagination')
@autoinject
@children({ name: 'columns', selector: ['ui-column', 'ui-table-action'] })
@processContent((compiler, resources, node, instruction) => {
  instruction.inheritBindingContext = true;
  return true;
})
export class UiTablePagination {
  @bindable({ defaultBindingMode: bindingMode.oneTime }) public rowClassComputedFrom: string | string[] = [];
  @bindable() public rowClass: string | ((record: KeyValue, isDesktopScreen: boolean) => string);
  @bindable() public onRowClicked: (obj: { record: KeyValue }) => void;
  @bindable() public service: ServiceBase<breeze.Entity>;
  @bindable() public afterLoad: (datas: KeyValue[]) => Promise<void> = null;
  @bindable() public sortBy: string = null;
  @bindable() public sortByDescending: boolean = false;
  @bindable() public nbRecordPerPage = 10;
  @bindable() public nbPagesToShow = 5;

  private datas: KeyValue[] = [];
  private dataToShow: KeyValue[] = [];
  @observable private currentPage = 1;
  private totalPages = 1;
  private disposables: Disposable[] = [];
  private columns = [];
  private customColumns = [];
  private pageLoaded: number[] = [];

  constructor(private globalLoaderService: GlobalLoaderService) { }

  public async bind() {
    if (this.service == null) {
      console.error("Service is mandatory in UiTablePagination");
      return;
    }
    await this.computeTotalPages();
    this.disposables.push(UIInternal.subscribe(Constants.UIInternal.EVT_UI_TABLE_PAGINATION_RELOAD, () => this.computeTotalPages()));
  }

  private async computeTotalPages() {
    const nbDatas = await this.service.getCount(this.service.gridDataSource.predicates, this.service.gridDataSource.queryParameters);
    if (this.nbRecordPerPage == 0) {
      this.nbRecordPerPage = 1;
    }
    this.totalPages = nbDatas / this.nbRecordPerPage;
    this.datas = new Array(nbDatas);
    this.setData();
  }

  public unbind() {
    this.disposables.forEach(x => x.dispose());
  }

  private async loadData() {
    if (this.totalPages > 0 && !this.pageLoaded.includes(this.currentPage)) {
      this.globalLoaderService.show();
      const nbDataToSkip = (this.currentPage - 1) * this.nbRecordPerPage;

      let dataLoaded = await this.service.getEntities(
        this.service.gridDataSource.predicates,
        this.service.gridDataSource.expands,
        this.service.gridDataSource.queryParameters,
        {
          propertyPaths: this.sortBy,
          isDescending: this.sortByDescending,
        },
        {
          take: this.nbRecordPerPage,
          skip: nbDataToSkip
        },
      );

      this.datas.splice(nbDataToSkip, dataLoaded.length, ...dataLoaded);
      this.pageLoaded.push(this.currentPage);

      if (this.afterLoad != null) {
        await this.afterLoad(dataLoaded);
      }
      this.globalLoaderService.hide();
    }
  }

  private async setData() {
    await this.loadData();
    this.dataToShow.splice(0, this.dataToShow.length, ...this.datas.slice().splice((this.currentPage - 1) * this.nbRecordPerPage, this.nbRecordPerPage));
  }

  public columnsChanged() {
    this.customColumns = this.columns;
  }

  public currentPageChanged(newValue, oldValue) {
    if (oldValue != null && newValue != oldValue) {
      this.setData();
    }
  }

  public goToLastPage() {
    this.currentPage = this.totalPages;
  }
}

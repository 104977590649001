import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, UIInternal, EnumerationTypeService, GridHelper, StringFilter, EnumerationFilter } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, bindable, BindingEngine, computedFrom, Container } from 'aurelia-framework';
import { Zeus } from "../../generated";
import { ColDef, FilterChangedEvent } from "ag-grid-community";
import * as Constants from '../../constants';
import { HttpClient, json } from 'aurelia-fetch-client';
import { Endpoints } from 'endpoints';
import { ApiService } from 'external-src/api.service';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { CustomPropertiesExtensionService } from 'extension-hooks/custom-properties/custom-properties-extension-service';
import { EntityType } from 'extension-hooks/custom-properties/custom-properties-model';

@autoinject
export class WorkOrderToProcessList extends ListViewModelBase<Zeus.Web.Model.WorkOrder> {
  public ressourceName: string = Constants.EntityTypeNames.WorkOrder;

  private workOrderTypeService: EnumerationTypeService;
  private orderSubtypeService: EnumerationTypeService;

  @bindable
  public workOrderSelected: Array<Zeus.Web.Model.WorkOrder>;
  @bindable
  private workOrderType: number;
  @bindable
  private subType: number;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient,
    private api: ApiService, private appModuleService: AppModuleService) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder));
    this.service.gridDataSource.expands = ['limitedToStorageGroup', 'workOrderPriority', 'workOrderType', 'orderSubtype', 'workOrderStatus', 'project', 'costCenter', 'inventoryTarget'];
    this.service.gridDataSource.queryParameters = { toProcess: true, byPriority: true };
    this.workOrderTypeService = new EnumerationTypeService(Constants.EnumerationTypes.WorkOrderType);
    this.orderSubtypeService = new EnumerationTypeService(Constants.EnumerationTypes.OrderSubtype);
  }

  public afterResetFilters() {
    this.agGridSortingHelper.applyDefaultSortModel();
  }

  public async attached() {
    UIInternal.subscribe(UIInternal.EVT_PAGINATION_CHANGE, () => {
      if (this.workOrderSelected?.length > 0 && this.selectedEntities.length != this.workOrderSelected.length) {
        this.gridOptions?.api?.forEachNode(x => {
          if (x.data == null) {
            return;
          }
          this.workOrderSelected.forEach(wo => {
            if (x.data.id == wo.id) {
              x.data.isSelected = true;
              this.gridOptions.api.ensureNodeVisible(x);
            }
          });
        });
      }
    });

    UIInternal.subscribe(Constants.WorkOrderProcessEvents.BatchProcessSent, (payload: { workOrderIdsToKeep: number[] }) => {
      for (let i = this.workOrderSelected.length - 1; i >= 0; --i) {
        if (false === payload.workOrderIdsToKeep.includes(this.workOrderSelected[i].id)) {
          (this.workOrderSelected[i] as any).isSelected = false;
          this.workOrderSelected.splice(i, 1);
        }
      }
      this.gridOptions?.api?.onFilterChanged();
      UIInternal.broadcast(Constants.WorkOrderProcessEvents.ReloadArticleToActions);
      if (this.showScanOrderSection) {
        this.clearOrderScanningSelection();
      }
    });

    UIInternal.subscribe(Constants.WorkOrderProcessEvents.ReloadWorkOrder, () => {
      this.gridOptions?.api?.onFilterChanged();
      this.workOrderSelected.splice(0);
    });

    this.disposables.push(
      this.bindingEngine.collectionObserver(this.workOrderSelected).subscribe(() => {
        this.gridOptions?.api?.redrawRows();
      }),
    );

    this.attachedOrderScanning();
    await super.attached();
  }

  public async detached(): Promise<void> {
    this.workOrderSelected?.forEach(wo => (<any>wo).isSelected = false);
    this._skipSaveGridColumnsState = true;
    await super.detached();
    this._skipSaveGridColumnsState = false;
  }

  protected _skipSaveGridColumnsState = false;
  protected saveGridColumnsState(): Promise<void> {
    if (!this._skipSaveGridColumnsState) {
      return super.saveGridColumnsState();
    }
  }

  protected isSelectable(entity: Zeus.Web.Model.WorkOrder): boolean {
    // Only allow selection if first work order OR if all already selected WO's have same type
    return (this.workOrderSelected.length == 0 || (
      this.workOrderSelected[0].workOrderTypeId == entity?.workOrderTypeId
      && this.workOrderSelected[0].orderSubtype == entity?.orderSubtype)
    ) && entity?.isSharedHierarchy;
  }

  public setWorkOrderSelected(event: { data: Zeus.Web.Model.WorkOrder & { isSelected?: boolean } }) {
    let i: number;
    if ((i = this.workOrderSelected.findIndex(x => x.id == event.data.id)) > -1) {
      if (!event.data.isSelected) {
        this.workOrderSelected.splice(i, 1);
        // Unload the workorder
        this.api.unloadWorkOrders([event.data.id]);
      }
    } else if (event.data.isSelected) {
      this.workOrderSelected.push(event.data);
    }
  }

  public onCellClicked(entity: Zeus.Web.Model.WorkOrder & { isSelected?: boolean }): boolean {
    if (entity.isSelected) {
      entity.isSelected = false;
    } else if (this.isSelectable(entity)) {
      entity.isSelected = true;
    }
    this.setWorkOrderSelected({ data: entity });
    return false;
  }

  public async initializeGridOptions(showFixedFilter?: boolean, hasHyperlinkColumn?: boolean | null): Promise<void> {
    super.initializeGridOptions(showFixedFilter, false);

    this.gridOptions.getRowNodeId = data => '' + data.id
    const onModelUpdated = this.gridOptions.onModelUpdated;
    this.gridOptions.onModelUpdated = async (event?) => {
      onModelUpdated(event);

      let workOrderIds: Array<number> = new Array();

      event.api?.forEachNode(rowNode => {
        if (rowNode.data?.id != null) {
          workOrderIds.push(rowNode.data.id);
        }
      });

      if (workOrderIds.length > 0) {
        let responsePrice: Response = await this.httpClient.post(Endpoints.WorkOrder.GetSharedHierarchy, json(workOrderIds));
        if (responsePrice.ok) {
          let result = await responsePrice.json();

          delete result["$id"];
          delete result["$type"];
          result.forEach((isSharedHierarchy, workerOrderId) => {
            let rowNode = event.api.getRowNode(workerOrderId);
            if (rowNode?.data != null) {
              rowNode.data.isSharedHierarchy = isSharedHierarchy;
            }
          });
        }
      }

      this.gridOptions?.api?.redrawRows();
    }

    const onFilterChanged = this.gridOptions.onFilterChanged;
    this.gridOptions.onFilterChanged = async (event: FilterChangedEvent<any>) => {
      if (onFilterChanged) {
        onFilterChanged(event);
      }
      await this.saveGridColumnsState();
    }
  }

  async onGridReady(event?: any): Promise<void> {
    if (this.workOrderType != null) {
      this.authService.currentUser.settings.dataGridSettings[this.routeName] = null;
    }
    await super.onGridReady(event);
    await this.loadDefaultFilter();
  }

  private async loadDefaultFilter() {
    // If we come from Dashboard
    if (this.workOrderType != null) {
      let instance = this.gridOptions?.api?.getFilterInstance("workOrderType.denomination._translation") as EnumerationFilter;
      await this.waitForFilterValues(instance);

      if (instance != null && instance.selectedIds.length == 0) {
        instance.filterValues?.forEach(value => value.selected = value.id == this.workOrderType);
      }

      if (this.subType != null) {
        let subInstance = this.gridOptions?.api?.getFilterInstance("orderSubtype.denomination._translation") as EnumerationFilter;
        await this.waitForFilterValues(subInstance);
        if (subInstance != null && subInstance.selectedIds.length == 0) {
          subInstance.filterValues?.forEach(value => value.selected = value.id == this.subType);
        }
      }
    }
  }

  private async waitForFilterValues(filter: EnumerationFilter) {
    if (filter == null) {
      return;
    }
    return new Promise(resolve => {
      const sub = this.bindingEngine.collectionObserver(filter.filterValues).subscribe(changeRecords => {
        if (changeRecords.length > 0 && changeRecords[0].addedCount > 0) {
          sub.dispose();
          resolve(true);
        }
      });
    });
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this, {
        onCellClicked: (event) => {
          this.setWorkOrderSelected(event)
        },
        headerComponent: null,
      }) as ColDef,
      {
        headerName: this.i18n.tr("workorder.id"),
        field: "id",
        sortable: false,
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "workOrderName",
        sortable: false,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workorder.workOrderStatusId"),
        field: "workOrderStatus.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderStatus,
        },
      },
      {
        headerName: this.i18n.tr("workorder.workOrderTypeId"),
        field: "workOrderType.denomination._translation",
        sortable: false,
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderType
        }
      },
      {
        headerName: this.i18n.tr("workorder.orderSubtypeId"),
        field: "orderSubtype.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.OrderSubtype,
        },
      },
      {
        headerName: this.i18n.tr("workorder.workOrderPriorityId"),
        field: "workOrderPriority.name._translation",
        sortable: false,
        cellRenderer: 'enumerationRenderer',
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: Constants.EntityTypeNames.WorkOrderPriority,
        }
      },
      {
        headerName: this.i18n.tr("workorder.inventoryTargetId"),
        field: "inventoryTarget.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.InventoryTarget,
        },
      },
      {
        headerName: this.i18n.tr("workorder.isFromERP"),
        field: "isFromERP",
        sortable: false,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorder.limitedToStorageGroupId"),
        field: "limitedToStorageGroup.name",
        sortable: false,
        type: FieldType.String
      },
      ...Container.instance.get(CustomPropertiesExtensionService).getColDefs(this, this.gridOptions, EntityType.WorkOrder),
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, {
        baseColDef: {
          sortable: false,
        }
      }),
    ];
    return defs;
  }

  //#region Order scanning
  private showScanOrderSection: boolean = false;
  private workOrderName: string;
  private workOrderNames: string[] = [];
  private workOrderNameInput: any;
  private typeIdSelected: number;

  @computedFrom('appModuleService')
  private get isOrderScanningActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.OrderScanning);
  }

  private attachedOrderScanning() {
    if (this.isOrderScanningActive) {
      this.disposables.push(
        this.bindingEngine.propertyObserver(this, 'workOrderName').subscribe(async (newVal, oldVal) => {
          if (newVal != null) {
            await this.scanOrder();
          }
        }),
      );
    }
  }

  private async scanOrder() {
    this.workOrderNames.push(this.workOrderName);
    this.refreshFilterByWONames();
    this.workOrderSelected.splice(0);
    this.selectAllWO();
  }


  private refreshFilterByWONames() {
    this.service.gridDataSource.getPredicatesFunction = () => Predicate.or(this.workOrderNames.map(x => new Predicate("workOrderName", FilterQueryOp.Equals, x)));
    this.gridOptions.api?.setDatasource(this.service.gridDataSource);
    this.gridOptions.api?.redrawRows();
  }

  private async selectAllWO() {
    setTimeout(async () => {
      if (this.typeIdSelected == null) {
        this.gridOptions?.api?.forEachNode(x => {
          if (x.data != null && x.data.isSharedHierarchy && this.typeIdSelected != Constants.WorkOrderType.Picking) {
            this.typeIdSelected = x.data.workOrderTypeId;
          }
        });
      }
      this.gridOptions?.api?.forEachNode(x => {
        if (x.data != null && x.data.workOrderTypeId == this.typeIdSelected && x.data.isSharedHierarchy) {
          x.data.isSelected = true;
          this.setWorkOrderSelected(x);
        }
      });
      this.workOrderName = null;
    }, 1000);
  }

  private scanOrderActivate() {
    this.showScanOrderSection = !this.showScanOrderSection;
    let columnDefs = this.gridOptions.api.getColumnDefs();
    columnDefs.forEach((columnDef: ColDef) => {
      columnDef.floatingFilter = false;
    });
    this.gridOptions.api.setColumnDefs(columnDefs);
    this.gridOptions.api.refreshHeader();
    this.clearOrderScanningSelection();
  }

  private clearOrderScanningSelection() {
    this.workOrderNames.splice(0);
    this.workOrderSelected.splice(0);
    this.service.gridDataSource.getPredicatesFunction = () => null;
    this.gridOptions.api?.setDatasource(this.service.gridDataSource);
    setTimeout(() => { this.gridOptions.api?.redrawRows(); }, 1000);
    this.typeIdSelected = null;
    this.workOrderNameInput?.focus();
  }
  //#endregion
} 
